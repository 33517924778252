<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Banners</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <a href="javascript:;" data-toggle="modal" data-target="#new-banner-modal" class="btn btn-primary shadow-md mr-2">New Banner</a>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="overflow-x-auto scrollbar-hidden">
          <div id="tabulator" ref="tableRef" class="mt-5"></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->

    <div id="new-banner-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">Banner</h2>
                </div>
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-6">
                        <div class="w-48 m-auto">
                            <img alt="Profile" class="w-full" :src="form_data.banner_url ? form_data.banner_url : require(`@/assets/images/spair.png`)" />
                            <input type="file" ref="upload_btn" class="hidden" @change="onFileChange">
                            <div class="flex items-center justify-end -mt-10">

                                <button class="btn btn-secondary mt-3 p-2 text-right rounded-t-2xl" @click="onFileUploadClick">
                                    <CameraIcon class="h-5 w-5 text-black" />

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-32 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-32" @click="submitForm">
                        Save Details
                    </button>
                    <button type="button" v-if="form_data.banner_id != ''" class="btn btn-danger w-32 ml-1" @click="deleteItem">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import cash from 'cash-dom'

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const upload_btn = ref('')
        const filter = reactive({
            field: 'banner_name',
            type: 'like',
            value: ''
        })
        const swal = inject('$swal')
        const errors = ref([])
        const banner_arr = ref([])

        const form_data = reactive({
            banner_image: "",
            banner_url: "",
            banner_id: "",
        })

        const onFileUploadClick = () => {
            upload_btn.value.click()
        }

        const onFileChange = (event) => {
            form_data.banner_image = event.target.files[0];

            form_data.banner_url = URL.createObjectURL(form_data.banner_image);
        }

        const submitForm = async () => {
            let formData = new FormData();

            formData.append("banner_image", form_data.banner_image);
            formData.append("id", form_data.banner_id);

            let config = {
                'content-type': 'multipart/form-data',
                'Accept': "application/json"
            }

            let uri = ""

            if (form_data.banner_id != "") {
                uri = "api/update_banner"
            } else {
                uri = "api/create_banner"

            }

            let promise = axios({
                url: uri,
                method: "post",
                baseURL: BASE_URL,
                data: formData,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "NO") {

                        swal("Success !", data.message, "success")

                        cash('#new-banner-modal').modal('hide')
                        initTabulator()

                        form_data.banner_image = ""
                        form_data.banner_url = ""
                        form_data.banner_id = ""
                        errors.value = []

                        return
                    }

                    swal("Error !", data.message, "error")
                    return
                })
                .catch(_error => {
                    errors.value = _error.response.data.errors
                    swal("Error !", "Something went wrong.", "error")
                    return
                });
        }

        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/get_banners',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                headerSort: false,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: 'No matching records found',
                columns: [{
                        formatter: 'responsiveCollapse',
                        width: 40,
                        minWidth: 30,
                        hozAlign: 'center',
                        resizable: false
                    },

                    // For HTML table
                    {
                        title: 'Banner Image',
                        minWidth: 120,
                        field: 'banner_image',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().banner_image != null) {
                                return `<div class="p-1 rounded-lg">
                  <img src="${ (cell.getData().banner_image ? cell.getData().banner_image : require(`@/assets/images/spair.png`)) }" class="w-44 h-28 rounded-lg" />
                </div>`
                            } else {
                                return `<div class="p-1 rounded-lg">
                                  <img src="${ (cell.getData().banner_image ? cell.getData().banner_image : require(`@/assets/images/spair.png`)) }" lass="w-44 h-28 rounded-lg" />
                                </div>`
                            }

                        },
                    },
                    {
                        title: 'Status',
                        minWidth: 100,
                        field: 'active_status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == "Active") {
                                return `<div class="flex items-center lg:justify-center text-theme-9">
                      Active
                    </div>`
                            } else {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                      Inactive
                    </div>`
                            }

                        }
                    },
                    {
                        title: 'Action',
                        width: 100,
                        field: '',
                        responsive: 1,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="flex lg:justify-center items-center">
                      <span class="flex items-center" >
                        <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                      </span>
                    </div>`)
                            cash(a).on('click', function () {
                                form_data.banner_id = window.btoa(cell.getData().id)

                                cash('#new-banner-modal').modal('show')

                                get_banner_details()
                            })
                            return a[0]
                        }
                    },
                    {
                        title: ' ',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == "Active") {
                                const a = cash(`<div class="p-0 m-0">
                              <button class="btn bg-theme-1 py-1 px-1 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="eye" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {
                                    changeStatus(cell.getData().id)
                                })

                                return a[0]

                            } else {
                                const a = cash(`<div class="p-0 m-0">
                              <button class="btn bg-theme-8 py-1 px-1 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="eye-off" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {
                                    changeStatus(cell.getData().id)
                                })

                                return a[0]
                            }
                        }
                    },
                    {
                        title: ' ',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            const a = cash(`<div class="p-0 m-0">
                        <button class="btn bg-theme-6 py-1 px-1 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
            <i data-feather="trash-2" class="w-5 h-5 mr-1"></i>
            </button>

            </div>`)
                            cash(a).on('click', function () {
                                deleteUser(cell.getData().id)
                            })

                            return a[0]
                        }
                    }

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const changeStatus = (id) => {

            swal({
                title: "Are you sure?",
                text: "You are about to Change Status Banner!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (id != "") {
                        uri = "api/inactive_banner"
                    } else {
                        uri = "api/inactive_banner"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: window.btoa(id)
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                initTabulator()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }
        const deleteUser = (id) => {

            swal({
                title: "Are you sure?",
                text: "You are about to Delete Banner!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (id != "") {
                        uri = "api/delete_banner"
                    } else {
                        uri = "api/delete_banner"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: window.btoa(id)
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                initTabulator()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }

        const get_banner_details = async () => {

            let params = {
                id: form_data.banner_id,
            }

            let config = {
                'Accept': "application/json"
            }

            let promise = axios({
                url: "api/edit_banner",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    form_data.banner_url = data.item.banner_image

                })
                .catch(_error => {
                    form_data.banner_url = ""
                });
        }

        const get_main_categories = async () => {
            let params = {}

            let config = {
                'Accept': "application/json"
            }

            let promise = axios({
                url: "api/list_banner",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    banner_arr.value = data.items

                })
                .catch(_error => {
                    manager_roles.value = []
                });
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // On reset filter
        const onResetFilter = () => {
            filter.field = 'banner_name'
            filter.type = 'like'
            filter.value = ''
            onFilter()
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'vendor_list.xlsx', {
                sheetName: 'Vendors'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {
            initTabulator()
            get_main_categories()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            editUser,
            form_data,
            errors,
            submitForm,
            onFileUploadClick,
            onFileChange,
            upload_btn,
            banner_arr
        }

    }
})
</script>
