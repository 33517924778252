<template>
<div>
    <div class="intro-y flex items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">{{ form_data.user_id == "" ? 'Create User' : 'Update User' }}</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button v-if="form_data.user_id != '' && form_data.active_status == 1" type="button" :class="{'btn shadow-md mr-2': true,
          'btn-danger ' : form_data.active_status == 1}" @click="changeStatus">
                <LockIcon class="w-4 h-4 mr-1" /> Deactivate
            </button>
            <button v-if="form_data.user_id != '' && form_data.active_status == 0" type="button" :class="{'btn shadow-md mr-2': true,
          'btn-success ' : form_data.active_status == 0}" @click="changeStatus">
                <UnlockIcon class="w-4 h-4 mr-1" /> Activate
            </button>
            <button v-if="form_data.user_id != ''" type="button" class="btn btn-danger shadow-md mr-2" @click="deleteUser">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Account
            </button>

        </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Profile Menu -->
        <div class="col-span-3 flex lg:block flex-col-reverse">
            <div class="intro-y box mt-5 p-5">
                <div class="w-full">
                    <img alt="Profile" class="w-full" :src="form_data.user_profile ? form_data.user_profile : require(`@/assets/images/user.png`)" />
                    <input type="file" ref="upload_btn" class="hidden" @change="onFileChange">
                    <div class="flex items-center justify-end">

                        <button class="btn btn-primary mt-3 p-2 text-right" @click="onFileUploadClick">Upload</button>
                    </div>
                </div>

            </div>
        </div>
        <!-- END: Profile Menu -->
        <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
            <!-- BEGIN: Change Password -->
            <div class="intro-y box lg:mt-5">
                <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">User Information</h2>
                </div>
                <div class="p-5">

                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="first_name" class="form-label">First Name <span class="text-theme-6">*</span></label>
                                <input id="first_name" type="text" v-model="form_data.first_name" class="form-control" placeholder="First Name" autocomplete="off" />
                            </div>
                            <span class="text-primary-3" v-if="errors.first_name">{{ errors.first_name[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="last_name" class="form-label">Last Name <span class="text-theme-6">*</span></label>
                                <input id="last_name" type="text" autocomplete="off" v-model="form_data.last_name" class="form-control" placeholder="Last Name" />
                            </div>
                            <span class="text-primary-3" v-if="errors.last_name">{{ errors.last_name[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="company_name" class="form-label">Company Name <span class="text-theme-6">*</span></label>
                                <input id="company_name" type="text" autocomplete="off" v-model="form_data.company_name" class="form-control" placeholder="Company Name" />
                            </div>
                            <span class="text-primary-3" v-if="errors.company_name">{{ errors.company_name[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="username" class="form-label">Username <span class="text-theme-6">*</span></label>
                                <input id="username" autocomplete="off" v-model="form_data.username" class="form-control" placeholder="Username" />
                            </div>
                            <span class="text-primary-3" v-if="errors.username">{{ errors.username[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">

                            <div class="mt-3">
                                <label for="mobile_no" class="form-label">Phone No. <span class="text-theme-6">*</span></label>
                                <input id="mobile_no" type="text" autocomplete="off" v-model="form_data.mobile_no" class="form-control" placeholder="Phone No" />
                            </div>
                            <span class="text-primary-3" v-if="errors.mobile_no">{{ errors.mobile_no[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">Address</label>
                                <input id="address" type="text" v-model="form_data.address" class="form-control" placeholder="Address" autocomplete="off" />
                            </div>
                            <span class="text-primary-3" v-if="errors.shipping_address">{{ errors.shipping_address[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">Land Mark</label>
                                <input id="address" type="text" v-model="form_data.land_mark" class="form-control" placeholder="Land Mark" autocomplete="off" />
                            </div>
                            <span class="text-primary-3" v-if="errors.land_mark">{{ errors.land_mark[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">City</label>
                                <input id="address" type="text" v-model="form_data.city" class="form-control" placeholder="City" autocomplete="off" />
                            </div>
                            <span class="text-primary-3" v-if="errors.city">{{ errors.city[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">State</label>
                                <input id="address" type="text" v-model="form_data.state" class="form-control" placeholder="State" autocomplete="off" />
                            </div>
                            <span class="text-primary-3" v-if="errors.state">{{ errors.state[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">PinCode</label>
                                <input id="address" type="text" v-model="form_data.pin_code" class="form-control" placeholder="Pin Code" autocomplete="off" />
                            </div>
                            <span class="text-primary-3" v-if="errors.pin_code">{{ errors.pin_code[0] }}</span>
                        </div>
                    </div>
                    <!-- <p class="text-theme-6 mt-5">In case information is incorrect kindly contact Admin for updating the information.</p> -->
                </div>
            </div>
            <div class="intro-y box lg:mt-5">
                <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Change Password</h2>
                </div>
                <div class="p-5">

                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="password" class="form-label">New Password <span class="text-theme-6">*</span></label>
                                <input id="password" type="password" class="form-control" minlength="6" maxlength="20" v-model="form_data.password" autocomplete="off" placeholder="New Password" />
                            </div>
                            <span class="text-primary-3" v-if="errors.password">{{ errors.password[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="confirm_password" class="form-label">Confirm New Password <span class="text-theme-6">*</span></label>
                                <input id="confirm_password" type="password" minlength="6" maxlength="20" v-model="form_data.confirm_password" class="form-control" placeholder="Confirm New Password" />
                            </div>
                            <span class="text-primary-3" v-if="errors.confirm_password">{{ errors.confirm_password[0] }}</span>
                        </div>
                    </div>

                    <div class="flex items-center justify-end">

                        <button class="btn btn-secondary mt-3 p-2 text-right mr-3" @click="this.$router.go(-1)">Cancel</button>
                        <button class="btn btn-primary mt-3 p-2 text-right" :disabled="form_data.loading == true" @click="submitForm">{{ form_data.user_id == "" ? 'Create User' : 'Update User' }}
                          <LoadingIcon v-if="form_data.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" />
                        </button>
                    </div>
                </div>
            </div>
            <!-- END: Change Password -->

            <div id="success-notification-content" class="toastify-content hidden flex">
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">Password Updated Successfully!</div>
                </div>
            </div>

            <div id="failed-notification-content" class="toastify-content hidden flex">
                <InfoIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">Validation Error!</div>
                    <div class="text-gray-600 mt-1">
                        Please Check Password & Confirm Password (min 6 char required) and try again
                    </div>
                </div>
            </div>

            <div id="failed-server-content" class="toastify-content hidden flex">
                <InfoIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">Server Error!</div>
                    <div class="text-gray-600 mt-1">
                        Something went wrong! Please Try again
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    mapGetters
} from 'vuex'
import {
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import {
    inject,
    onMounted,
    reactive,
    ref
} from 'vue'
import router from '@/router'
import {
    useRoute
} from 'vue-router'

export default {
    setup() {
        const user_profile = ref('')
        const upload_btn = ref('')
        const route = useRoute()
        const swal = inject('$swal')
        const errors = ref([])
        const form_data = reactive({
            first_name: "",
            company_name: "",
            last_name: "",
            mobile_no: "",
            username: "",
            user_profile: "",
            address: "",
            user_type: 3,
            password: "",
            city: "",
            state: "",
            pin_code: "",
            land_mark: "",
            confirm_password: "",
            active_status: "",
            user_id: route.params.user_id ? route.params.user_id : "",
            loading: false
        })
        const onFileUploadClick = () => {
            upload_btn.value.click()
        }

        const onFileChange = (event) => {
            user_profile.value = event.target.files[0];

            form_data.user_profile = URL.createObjectURL(user_profile.value);
        }

        const submitForm = async () => {
            let formData = new FormData();

            formData.append("first_name", form_data.first_name);
            formData.append("last_name", form_data.last_name);
            formData.append("mobile_no", form_data.mobile_no);
            formData.append("username", form_data.username);
            formData.append("user_profile", user_profile.value);
            formData.append("shipping_address", form_data.address);
            formData.append("company_name", form_data.company_name);
            formData.append("user_type", form_data.user_type);
            formData.append("city", form_data.city);
            formData.append("state", form_data.state);
            formData.append("pin_code", form_data.pin_code);
            formData.append("land_mark", form_data.land_mark);
            formData.append("password", form_data.password);
            formData.append("confirm_password", form_data.confirm_password);
            formData.append("id", form_data.user_id);

            let config = {
                'content-type': 'multipart/form-data',
                'Accept': "application/json"
            }

            let uri = ""

            if (form_data.user_id != "") {
                uri = "api/update_user"
            } else {
                uri = "api/create_user"

            }

            form_data.loading = true
            let promise = axios({
                url: uri,
                method: "post",
                baseURL: BASE_URL,
                data: formData,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "NO") {

                        swal("Success !", data.message, "success")

                        router.push({
                            path: "/master/user/list"
                        })
                        return
                    }
                    form_data.loading = false

                    swal("Error !", data.message, "error")
                    return
                  })
                  .catch(_error => {
                    form_data.loading = false
                    errors.value = _error.response.data.errors
                    swal("Error !", "Something went wrong.", "error")
                    return
                });
        }
        const get_user_details = async () => {

            let params = {
                id: route.params.user_id,
            }

            let config = {
                'Accept': "application/json"
            }

            let promise = axios({
                url: "api/edit_user",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    form_data.first_name = data.item.first_name ? data.item.first_name : ""
                    form_data.last_name = data.item.last_name ? data.item.last_name : ""
                    form_data.mobile_no = data.item.mobile_no ? data.item.mobile_no : ""
                    form_data.username = data.item.email ? data.item.email : ""
                    form_data.user_profile = data.item.user_profile ? data.item.user_profile : ""
                    form_data.address = data.item.shipping_address ? data.item.shipping_address : ""
                    form_data.user_type = data.item.user_type ? data.item.user_type : ""
                    form_data.password = data.item.password ? data.item.password : ""
                    form_data.city = data.item.city == "" || data.item.city == null ? "" : data.item.city
                    form_data.state = data.item.state == "" || data.item.state == null ? "" : data.item.state
                    form_data.pin_code = data.item.pin_code == "" || data.item.pin_code == null ? "" : data.item.pin_code
                    form_data.land_mark = data.item.land_mark == "" || data.item.land_mark == null ? "" : data.item.land_mark
                    form_data.company_name = data.item.company_name == "" || data.item.company_name == null ? "" : data.item.company_name
                    form_data.confirm_password = data.item.password == "" || data.item.password == null ? "" : data.item.password
                    form_data.active_status = data.item.active_status == "" || data.item.active_status == null ? "" : data.item.active_status
                    // form_data.user_id = data.item.user_id

                })
                .catch(_error => {
                    form_data.first_name = ""
                    form_data.last_name = ""
                    form_data.mobile_no = ""
                    form_data.username = ""
                    form_data.user_profile = ""
                    form_data.address = ""
                    form_data.user_type = ""
                    form_data.password = ""
                    form_data.city = ""
                    form_data.state = ""
                    form_data.pin_code = ""
                    form_data.land_mark = ""
                    form_data.company_name = ""
                    form_data.confirm_password = ""
                    form_data.active_status = ""
                    // form_data.user_id = ""
                });
        }

        const changeStatus = () => {

            swal({
                title: "Are you sure?",
                text: "You are about to ChangeStatus User!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (form_data.user_id != "") {
                        uri = "api/inactive_user"
                    } else {
                        uri = "api/inactive_user"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: form_data.user_id
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                get_user_details()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }
        const deleteUser = () => {

            swal({
                title: "Are you sure?",
                text: "You are about to Delete User!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (form_data.user_id != "") {
                        uri = "api/delete_user"
                    } else {
                        uri = "api/delete_user"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: form_data.user_id
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                router.push({
                                    path: "/master/user/list"
                                })
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }

        onMounted(() => {
            if (route.params.user_id) {
                get_user_details()
            }
        })

        return {
            form_data,
            upload_btn,
            user_profile,
            onFileChange,
            submitForm,
            onFileUploadClick,
            errors,
            changeStatus,
            deleteUser
        }
    },
}
</script>
