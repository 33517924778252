<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Customers</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <router-link :to="{ name: 'user-create' }" tag="button" class="btn btn-primary shadow-md mr-2">
                Add New User
            </router-link>

        </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="grid grid-cols-12 gap-x-2">
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Field</label>
                            <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select">
                                <option value="first_name">Customer Name</option>
                                <option value="email">User Name</option>
                                <option value="mobile_no">Contact No</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Type</label>
                            <select id="tabulator-html-filter-type" v-model="filter.type" class="form-select w-fullo">
                                <option value="like" selected>like</option>
                                <option value="=">=</option>
                                <option value="<">&lt;</option>
                                <option value="<=">&lt;=</option>
                                <option value=">">></option>
                                <option value=">=">>=</option>
                                <option value="!=">!=</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Value</label>
                            <input id="tabulator-html-filter-value" v-model="filter.value" type="text" class="form-control sm:w-40" placeholder="Search..." autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full" @click="onFilter">
                            Go
                        </button>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full" @click="onResetFilter">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
            <div class="flex mt-5 sm:mt-0">

                <div class="dropdown w-1/2 sm:w-auto">
                    <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export
                        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                    </button>
                    <div class="dropdown-menu w-40">
                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                            <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportCsv">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                            </a>
                            <a id="tabulator-export-json" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportJson">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                            </a>
                            <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const tableRef = ref()
        const swal = inject('$swal')
        const tabulator = ref()
        const filter = reactive({
            field: 'first_name',
            type: 'like',
            value: ''
        })

        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/get_users',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                headerSort: false,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: 'No matching records found',
                columns: [{
                        formatter: 'responsiveCollapse',
                        width: 40,
                        minWidth: 30,
                        hozAlign: 'center',
                        resizable: false
                    },

                    // For HTML table
                    {
                        title: 'Profile',
                        minWidth: 120,
                        field: 'company_name',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().user_profile != null) {
                                return `<div class="p-1 rounded-lg">
              <img src="${ (cell.getData().user_profile ? cell.getData().user_profile : require(`@/assets/images/user.png`)) }" class="w-16 h-12 rounded-lg" />
            </div>`
                            } else {
                                return `<div class="p-1 rounded-lg">
                              <img src="${ (cell.getData().user_profile ? cell.getData().user_profile : require(`@/assets/images/user.png`)) }" class="w-12 h-12 rounded-lg" />
                            </div>`
                            }

                        },
                        cellClick: function (e, cell) {
                            router.push({
                                path: "/master/user/update/" + window.btoa(cell.getData().user_id)
                            })
                        },
                    },
                    {
                        title: 'User Name ',
                        minWidth: 0,
                        field: 'email',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        cellClick: function (e, cell) {
                            router.push({
                                path: "/master/user/update/" + window.btoa(cell.getData().user_id)
                            })
                        }
                    },
                    {
                        title: 'Name',
                        minWidth: 120,
                        responsive: 0,
                        field: 'first_name',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                        cellClick: function (e, cell) {
                            router.push({
                                path: "/master/user/update/" + window.btoa(cell.getData().user_id)
                            })
                        },
                        formatter(cell) {
                            if (cell.getData().last_name != null) {
                                return `<router-link :to="{ name: 'view-broker-account' }" tag="div">
              <div class="font-medium whitespace-nowrap" >${
                  cell.getData().first_name
                } ${
                  cell.getData().last_name
                }</div>
            </router-link>`
                            } else {
                                return `<router-link :to="{ name: 'view-broker-account' }" tag="div">
              <div class="font-medium whitespace-nowrap" >${
                  cell.getData().first_name
                }</div>
            </router-link>`
                            }

                        }
                    },

                    {
                        title: 'Company Name',
                        minWidth: 120,
                        field: 'company_name',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true
                    },
                    {
                        title: 'Mobile No',
                        minWidth: 100,
                        field: 'mobile_no',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: 'textarea'
                    },
                    {
                        title: 'Status',
                        minWidth: 100,
                        field: 'active_status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == "Active") {
                                return `<div class="flex items-center lg:justify-center text-theme-9">
                Active
              </div>`
                            } else {
                                return `<div class="flex items-center lg:justify-center text-theme-6">
                Inactive
              </div>`
                            }

                        }
                    },
                    {
                        title: 'Action',
                        width: 100,
                        field: '',
                        responsive: 1,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                            const a = cash(`<div class="flex lg:justify-center items-center">
                <span class="flex items-center" >
                  <i data-feather="edit" class="w-4 h-4 mr-1"></i> Edit
                </span>
              </div>`)
                            cash(a).on('click', function () {
                                router.push({
                                    path: "/master/user/edit/" + window.btoa(cell.getData().user_id)
                                })
                            })

                            // router.push({
                            //     path: "/master/user/edit/" + window.btoa(cell.getData().user_id)
                            // })

                            return a[0]
                        }
                    },
                    {
                        title: ' ',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if (cell.getData().active_status == "Active") {
                                const a = cash(`<div class="p-0 m-0">
                              <button class="btn bg-theme-1 py-1 px-1 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="eye" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {
                                    changeStatus(cell.getData().user_id)
                                })

                                return a[0]

                            } else {
                                const a = cash(`<div class="p-0 m-0">
                              <button class="btn bg-theme-8 py-1 px-1 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="eye-off" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {
                                    changeStatus(cell.getData().user_id)
                                })

                                return a[0]
                            }
                        }
                    },
                    {
                        title: ' ',
                        field: 'actions',
                        responsive: 1,
                        width: 50,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                          const a = cash(`<div class="p-0 m-0">
                        <button class="btn bg-theme-6 py-1 px-1 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
            <i data-feather="trash-2" class="w-5 h-5 mr-1"></i>
            </button>

            </div>`)
                          cash(a).on('click', function () {
                              deleteUser(cell.getData().user_id)
                          })

                          return a[0]
                        }
                    }

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const changeStatus = (id) => {

            swal({
                title: "Are you sure?",
                text: "You are about to ChangeStatus User!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (id != "") {
                        uri = "api/inactive_user"
                    } else {
                        uri = "api/inactive_user"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: window.btoa(id)
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                initTabulator()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }
        const deleteUser = (id) => {

            swal({
                title: "Are you sure?",
                text: "You are about to Delete User!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (id != "") {
                        uri = "api/delete_user"
                    } else {
                        uri = "api/delete_user"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: window.btoa(id)
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                initTabulator()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // On reset filter
        const onResetFilter = () => {
            filter.field = 'first_name'
            filter.type = 'like'
            filter.value = ''
            onFilter()
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'vendor_list.xlsx', {
                sheetName: 'Vendors'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {
            initTabulator()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            editUser
        }

    }
})
</script>
