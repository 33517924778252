<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Invoice Layout</h2>
        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
            <button class="btn btn-primary shadow-md mr-2" @click="printReportDetails">Print</button>
            <!-- <div class="dropdown ml-auto sm:ml-0"> -->
                <!-- <button class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                    <span class="w-5 h-5 flex items-center justify-center">
                        <PlusIcon class="w-4 h-4" />
                    </span>
                </button> -->
                <!-- <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                            <FileIcon class="w-4 h-4 mr-2" /> Export Word
                        </a>
                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                            <FileIcon class="w-4 h-4 mr-2" /> Export PDF
                        </a>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
    <!-- BEGIN: Invoice -->
    <div class="intro-y box overflow-hidden mt-5">
        <div class="border-b border-gray-200 dark:border-dark-5 text-center sm:text-left">
            <div class="px-5 py-10 sm:px-20 sm:py-20">
                <div class="text-theme-1 dark:text-theme-10 font-semibold text-3xl">
                    INVOICE
                </div>
                <div class="mt-2">
                    Receipt <span class="font-medium">{{ page_data.order_detail.id }}</span>
                </div>
                <div class="mt-1">{{ page_data.order_detail.created_time }}</div>
            </div>
            <div class="flex flex-col lg:flex-row px-5 sm:px-20 pt-10 pb-10 sm:pb-20">
                <div>
                    <div class="text-base text-gray-600">Customer Details</div>
                    <div class="text-lg font-medium text-theme-1 dark:text-theme-10 mt-2">
                        {{ page_data.order_detail.first_name + ' ' +  page_data.order_detail.last_name }}
                    </div>
                    <!-- <div class="text-lg font-medium text-theme-1 dark:text-theme-10 mt-2">
                        {{ page_data.order_detail.company_name }}
                    </div> -->
                    <div class="mt-1">{{ page_data.order_detail.email }}</div>
                    <div class="mt-1">{{ page_data.order_detail.address }}</div>
                </div>
                <!-- <div class="lg:text-right mt-10 lg:mt-0 lg:ml-auto">
                    <div class="text-base text-gray-600">Payment to</div>
                    <div class="text-lg font-medium text-theme-1 dark:text-theme-10 mt-2">
                        Left4code
                    </div>
                    <div class="mt-1">left4code@gmail.com</div>
                </div> -->
            </div>
        </div>
        <div class="px-5 sm:px-16 py-10 sm:py-20">
            <div class="overflow-x-auto">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                                Image
                            </th>
                            <th class="border-b-2 dark:border-dark-5 text-left whitespace-nowrap">
                                Product
                            </th>
                            <th class="border-b-2 dark:border-dark-5 text-left whitespace-nowrap">
                                QTY
                            </th>
                            <th class="border-b-2 dark:border-dark-5 text-left whitespace-nowrap">
                                Number (Items)
                            </th>
                            <th class="border-b-2 dark:border-dark-5 text-left whitespace-nowrap">
                                PRICE (Per Item)
                            </th>
                            <th class="border-b-2 dark:border-dark-5 text-left whitespace-nowrap">
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in page_data.item_details" :key="index">
                            <td class="border-b dark:border-dark-5">
                                <img :src="item.base_image" class="w-16 h-12 rounded-lg" alt="">
                            </td>
                            <td class="border-b dark:border-dark-5 text-left">{{ item.product_name }}</td>
                            <td class="border-b dark:border-dark-5 text-left">{{ item.on_piece_qty + ' ' + item.uqc_code }}</td>
                            <td class="border-b dark:border-dark-5 text-left">{{ item.quantity }}</td>
                            <td class="border-b dark:border-dark-5 text-left">{{ item.per_unit }}</td>
                            <td class="border-b dark:border-dark-5 text-left">{{ item.total }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="px-5 sm:px-20 pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row">
            <div class="text-center sm:text-left mt-10 sm:mt-0">
                <!-- <div class="text-base text-gray-600">Bank Transfer</div>
                <div class="text-lg text-theme-1 dark:text-theme-10 font-medium mt-2">
                    Elon Musk
                </div>
                <div class="mt-1">Bank Account : 098347234832</div>
                <div class="mt-1">Code : LFT133243</div> -->
            </div>
            <div class="text-center sm:text-right sm:ml-auto">
                <div class="text-base text-gray-600">Total Amount</div>
                <div class="text-xl text-theme-1 dark:text-theme-10 font-medium mt-2">
                    {{ page_data.order_detail.total? page_data.order_detail.total : 0 }}
                </div>
                <!-- <div class="mt-1 tetx-xs">Taxes included</div> -->
            </div>
        </div>
    </div>
    <!-- END: Invoice -->
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const page_data = reactive({
            order_detail: [],
            item_details: []
        })
        const swal = inject('$swal')

        const get_order_details = async () => {

            let params = {
                id: route.params.order_id
            }
            let config = {
                'Accept': "application/json"
            }

            let promise = axios({
                url: "api/get_single_order_details",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.details.length > 0) {

                        page_data.order_detail = data.order
                        page_data.item_details = data.details

                        return
                    }
                    page_data.order_detail = []
                    page_data.item_details = []

                    swal("Error !", "Details not Found", "error")
                    return
                })
                .catch(_error => {
                    swal("Error !", "Something went wrong.", "error")
                    return
                });
        }

        function printReportDetails() {
            let report_type = 'order-details'

            let params = {
                report_type: report_type,
                items: {'order_detail': page_data.order_detail,'item_details': page_data.item_details }
            }
            let promise = axios({
                url: "api/add_report_details",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                  let url = BASE_URL + data.report_type +'?id=' + window.btoa(data.report_id)
                        window.open(url, '_blank');

                })
                .catch(_error => {

                });
            // add_report_details
        }

        onMounted(() => {
            get_order_details()

        })

        return {
            page_data,
            printReportDetails
        }

    }
})
</script>
