/* eslint-disable */
import { BASE_URL } from '@/constants/config.js'
export default (to, from, next) => {
    console.log(to.fullPath)
    if (localStorage.getItem('token') != null && localStorage.getItem('token').length > 0) {
        // verify with firebase or jwt
        axios({
            url: 'api/user_details',
            method: 'get',
            baseURL: BASE_URL,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept': 'application/json' } }
        }).then(response => {
            next()

        }).catch(response => {
            next('/login')
        });

    } else {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        next('/login')
    }
}
