<template>
<!-- BEGIN: Top Bar -->
<div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
        <a href="">Application</a>
        <ChevronRightIcon class="breadcrumb__icon" />
        <a href="" class="breadcrumb--active">Dashboard</a>
    </div>
    <!-- END: Notifications -->
    <!-- BEGIN: Account Menu -->
    <div class="intro-x dropdown w-8 h-8">
        <div class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false">
            <img alt="Icewall Tailwind HTML Admin Template" :src="userDetails.user_profile ? userDetails.user_profile : require(`@/assets/images/user.png`)" />
        </div>
        <div class="dropdown-menu w-56">
            <div class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">
                <div class="p-4 border-b border-theme-27 dark:border-dark-3">
                    <div class="font-medium">{{ userDetails.first_name }} {{ userDetails.last_name }}</div>
                    <div class="text-xs text-theme-28 mt-0.5 dark:text-gray-600">
                        {{ userDetails.email }}
                    </div>
                </div>
                <div class="p-2">
                    <router-link tag='a' :to="{ name: 'change-password' }" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                        <UserIcon class="w-4 h-4 mr-2" /> Change Password
                    </router-link>

                </div>
                <!-- <div class="p-2">
             <router-link tag='a'
            :to="{ name: 'update-profile' }"

              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"
            >
              <UserIcon class="w-4 h-4 mr-2" /> My Profile
            </router-link>

          </div> -->
                <div class="p-2 border-t border-theme-27 dark:border-dark-3">
                    <span @click="logOut" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md">
                        <ToggleRightIcon class="w-4 h-4 mr-2" /> Logout
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- END: Account Menu -->
</div>
<!-- END: Top Bar -->
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    computed,
    ref
} from 'vue'
import {
    useStore
} from '@/store'
import {
    useRoute,
    useRouter
} from 'vue-router'

export default defineComponent({
    setup() {
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const searchDropdown = ref(false)

        const userDetails = computed(() => store.state.user.currentUser)

        const logOut = () => {
            console.log('hi');
            store.dispatch('signOut').then(() => {
                router.go("/login");
            });
        }

        const showSearchDropdown = () => {
            searchDropdown.value = true
        }

        const hideSearchDropdown = () => {
            searchDropdown.value = false
        }

        return {
            searchDropdown,
            showSearchDropdown,
            hideSearchDropdown,
            userDetails,
            logOut
        }
    }
})
</script>
