<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 mt-8 lg:col-span-12">
          <div class="intro-y box">
            <div
              class="flex flex-col items-center p-5 border-b border-gray-200 sm:flex-row dark:border-dark-5"
            >
              <h2 class="text-base font-medium text-center w-full">Terms & Conditions</h2>
            </div>

            <div class="col-span-12 px-5">
                <h2 class="mt-4 text-base font-medium">GENERAL</h2>
                <p class="py-4">
                  This document is an electronic record in terms of The Information Technology Act, 2000 ( "IT Act") and rules thereunder, and is published in accordance with the provisions of Rule 3 (1) under IT Act which mandates for publishing of rules and regulations, privacy policy and terms of use for access or usage of the Website and application. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                  Amit Enterprise ("Website and application") is an online selling channel of Amit Enterprise, having its office at Plot No. 311, Sonal Industries, Estate - 1, Nr. Chikuwadi, Pandesara, Udhana, Surat.
                  Your use / access of the Website and application, its related sites and hyperlinks, shall be governed by the terms and conditions, policies, rules of Amit Enterprise ("Terms") posted on the Website and application from time to time. These Terms applies to all users (whether Registered user or Guest User) of the Website and application.
                  Please read the Terms and conditions very carefully before using the Website and application as your use of the Website and application is subject to your acceptance of and compliance with the Terms. If you do not agree or are not willing to be bound by the terms and conditions of this User Agreement and any Rules and Policies of Amit Enterprise, please do not click on the " I Accept" button/check box and on the "Continue" button or "Pay Now" button and do not subscribe or seek to obtain access to or otherwise use the Website and application.
                  By subscribing to or using the website and application or any of our services through the Website and application you agree that you have read, understood and are bound by the Terms, Policies, directions of Amit Enterprise regardless of how you subscribe to or use the Website and application. This Agreement comes into effect upon your "Acceptance" of these terms and conditions ("Terms") however Amit Enterprise at its sole discretion reserves its right not to accept a user from registering on the Website and application without assigning any reason thereof.
                </p>

                <h2 class="text-base font-medium">DEFINITION</h2>
                <p class="py-4">
                  "Agreement" means the terms and conditions as detailed herein including all schedules, appendices, annexures, Privacy Policy, Disclaimer and will include the references to this Agreement as amended, novated, supplemented, varied or replaced from time to time."
                  "You"/ "User" shall mean the end user accessing the Website and application, its contents and using the services offered through the Website and application."
                  "Amit Enterprise"/ "we"/ "us"/ "our" shall mean Amit Enterprise or its affiliates, franchisees, service providers, successors and assigns "Site" means the online shopping platform owned and operated by Amit Enterprise. ("Amit Enterprise"), which provides a venue to the users of Amit Enterprise to buy the Products listed on Site."
                  "Customer"/"Buyer" shall mean the person or any legal entity who access the Website and application and places an order for and or purchases any products /services against the invitation of offer for sale of products / services on the Website and application."
                  "Acceptance" shall mean your affirmative action in clicking on "check box"â„¢ and on the "continue button as provided on the registration page or clicking on"
                  "ËœPay Now" button while transacting as User or such other actions that implies your acceptance."
                  "Product" shall mean the Products offered for sale on the website and application from time to time"
                  "Delivery" shall mean the physical delivery of goods under your order placed with us"
                  "Price" means the price communicated at the time of order and confirmed by us"
                  "Payment Facility" shall mean applicable mode of payments made available to User from time to time."
                  "Content" means all text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code collectively By impliedly or expressly accepting this User Agreement, Policies and Terms, You also accept and agree to be bound by Rules, Policies and Terms of Amit Enterprise as provided from time to time in its Website and application and corresponding hyperlinks."
                </p>

                <h2 class="text-base font-medium">PURCHASE</h2>
                <h3 class="mt-4 text-base font-medium">1) Registration and Submission of personal details: </h3>
                <p class="py-4">
                  You can use the services offered by the Website and application as a "Registered User". As part of the registration process on the Site, Amit Enterprise may collect certain personally identifiable information about you including first and last name, email address, mobile phone number and contact details, Postal code, Demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information. <br>
                  As a Registered User, you are responsible for maintaining the confidentiality of your user id and password. You are also responsible for maintaining the confidentiality of your email id and mobile number and for any unauthorised access to your mobile , tab, computer, computer system and computer network, and you are responsible for all activities that occur under your user id and password, email id and mobile number as the case may be. You agree to maintain and promptly update all data provided by you and to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the Terms, we have the right to indefinitely suspend or terminate or block access of your user account with the Website and application and refuse to provide you with access to the Website and application. <br>
                  We will collect personally identifiable information about you only as part of a voluntary registration process, on-line survey, or contest or any combination thereof. <br>
                </p>
                <h3 class="text-base font-medium">2) User's Liability: </h3>
                <p class="py-4">
                  The User hereby affirms that the information provided by him / her is true, correct and complete to the best of his / her knowledge and belief. The User agrees and understands that he / she will be solely responsible in the event of any inaccuracy or deviation therefrom at a later stage.
                  <br>
                  You agree to comply with all applicable laws in connection with your use of the Website and application, and such further limitations as may be set forth in any written or on-screen notice from us.
                  <br>
                  To register with & shop with Amit Enterprise you must be eighteen years of age or over. Our website and application is not directed to children under the age of 18. If we become aware that a child and/ or customer is under the age of 18 and has registered or using our Website and application, we are entitled to remove his or her account from our system and cancel any order placed by him or her.
                  <br>
                  You agree and undertake not to reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website and application. Reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of the Website and application is not permitted
                  <br>
                  You agree and undertake not to display, upload, modify, publish, transmit, update or share any information that:
                  <br><br>
                  i. belongs to another person and to which you do not have any legal right
                  <br><br>
                  ii. is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner what so ever;
                  <br><br>
                  iii. harm minors in any way;
                  <br><br>
                  iv. infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy;
                  <br><br>
                  v. violates any law for the time being in force;
                  <br><br>
                  vi. impersonate another person
                  <br><br>
                  vii. contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any Trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information.
                  <br><br>
                  You, as a Buyer, understand that upon initiating a transaction you are entering into a legally binding and enforceable contract with Amit Enterprise to purchase the products and /or services from Amit Enterprise, and pay the transaction price to Amit Enterprise using appropriate Payment Facility. You, as a Buyer, understand that the all payment facility may not be available in full or in part for certain category of products and for certain locations and hence you may not be entitled to use that Payment Facility in respect of the transactions for those products or locations.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">3) Availability of Website and application and its services: </h3>
                <p class="py-4">
                  This Website and application is controlled and operated by Amit Enterprise from surat and Amit Enterprise makes no representation that the materials are appropriate or will be available for use in other locations. If you use this web site from outside the surat, you are entirely responsible for compliance with all applicable local laws.
                  <br>
                  Amit Enterprise has several website and applications offering products, services, content and various other functionalities (collectively the "Services") to specific regions worldwide. The Services offered in one region may differ from those in other regions due to availability, local or regional laws, shipment and other considerations. Amit Enterprise does not make any warranty or representation that a user in one region may obtain the Services from the Amit Enterprise site in another region and Amit Enterprise may cancel a user's order or redirect a user to the site for that user's region if a user attempts to order Services offered on a site in another region.
                  <br>
                  Information that Amit Enterprise publishes on the World Wide Web may contain references or cross references to Amit Enterprise products, programs and services that are not announced or available in your country. Such references do not imply that Amit Enterprise intends to announce such products, programs or services in your country. Consult your local Amit Enterprise business contact for information regarding the products, programs and services that may be available to you.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">4) Access to Website: </h3>
                <p class="py-4">
                  We will do our utmost to ensure that availability of the website and application will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the website and application may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">5) Permission to access website and application: </h3>
                <p class="py-4">
                  Amit Enterprise grants you a limited, revocable permission to access and make personal use of this Website and application, but not to download or modify it, its Contents or any portion of it, except with express written consent of Amit Enterprise. This permission does not include any resale or commercial use of this website and application or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this website and application or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools
                  <br>
                  The use of the Website and application and its Content grants no rights to you in relation to any copyright, designs, trademarks and all other intellectual property and material rights relating to the Content. Including the content but not limited to the design, structure, selection, coordination, expression, "look and feel" and arrangement of such Content, contained on the Website and application are original to Amit Enterprise and are owned and controlled or licensed by us, and are protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights.
                  <br>
                  Except as expressly provided in Terms of Use, no part of the Website and application and no Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, website and application or other medium for publication or distribution or for any commercial enterprise, without our express prior written consent.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">6) Applicability of Terms: </h3>
                <p class="py-4">
                  You will be subject to the policies, conditions and Terms of use in force at the time that you use the website and application or that you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you).
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">7) Communications: </h3>
                <p class="py-4">
                  When you place an order on our Website and application or send e-mails to us, you are communicating with us electronically so you must provide a valid mobile phone number / email address and your address while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the Website and application or by any other mode of communication. For contractual purposes, you consent to receive communications including SMS, e-mails or phone calls from us with respect to your order.
                  <br>
                  Your Obligations:
                  <br>
                  You shall
                  <br><br>
                  i. not acquire any ownership rights by downloading material /Contents from this website and application.
                  <br><br>
                  ii. read the Terms and policies of website and application and agree to accept the Terms before use of Website and application
                  <br><br>
                  iii. abide by the Terms and Policies of Website and application
                  <br><br>
                  iv. Neither copy or modify the Contents of the website and application nor use the Website and application for commercial purpose
                  <br><br>
                  v. purchase the product(s) from this Website and application for your internal / personal purpose and not for resale by you or any other person.
                  <br><br>
                  vi. comply with all applicable laws in connection with your use of the Website and application, and such further limitations as may be set forth in any written or on-screen notice from us.
                  <br><br>
                  vii. not refuse to accept the delivery of the Products under your order upon delivery except for damages and deficiencies
                  <br><br>
                  viii. authorize us to declare and provide declaration to any governmental authority on your behalf stating the aforesaid purpose of the products ordered by you on the website and application.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">8) Products/Services: </h3>
                <p class="py-4">
                  All products/services and information displayed on Website and application constitute an "invitation to offer". Your order for purchase constitutes your "offer". All orders placed by you on the Website and application are subject to availability and acceptance by us and conditional on your acceptance and adherence to these terms and conditions.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">9) Our Contract: </h3>
                <p class="py-4">
                  Your order is an offer to us to buy the product(s) in your order. We will confirm our acceptance of your offer either through an e-mail / SMS or through shipment of the items you requested. Upon placing an order, you will receive an e-mail confirming receipt of your order with details (the "Order Confirmation E-mail"). The Order Confirmation E-mail is an acknowledgement that we have received your order, and does not confirm acceptance of your offer to buy the product(s) ordered. Our acceptance to your offer takes place and the contract of sale for a product ordered by you concludes only upon actual dispatch of products to you and an e-mail confirmation in that regard is sent to you (the "Dispatch E-mail"). We, at our sole discretion, reserve our right to limit the quantity of items and cancel any order, even after acceptance.
                  <br>
                  Amit Enterprise has complete right to accept or reject an order placed online. No act or omission of Amit Enterprise prior to actual dispatch of the product(s) ordered will constitute an acceptance of your offer. You agree not to dispute the decision of Amit Enterprise in this regard.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">10) Delivery/Shipment of Product: </h3>
                <p class="py-4">
                  Delivery of the Products under your Order in the Website and application shall be made as per our Delivery/shipment policy mentioned herein. Title of the Product shall pass on to you upon receipt of payment.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">11) Exactness / Accuracy of product: </h3>
                <p class="py-4">
                  We have made significant efforts to accurately display the colours / dimension / specification of products that appear on the Website and application. However, we do not warrant/guarantee that product description or other content of this Website and application is accurate, complete, reliable, current, or error-free. The particular technical specifications and settings of your computer and its display could affect the accuracy of its display of the colours, dimension, specifications of products offered on this Website and application.
                  <br>
                  Copyright: All Content included on the website and application, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Amit Enterprise, its affiliates or its content suppliers and is protected by the Intellectual Property Laws of India.
                  <br>
                  Trademarks: The trademark Amit Enterprise, its logo and all products and logos denoted with TM are trademarks owned or registered trademarks of Amit Enterprise or its affiliates.
                  <br>
                  Intellectual Property Claims: Amit Enterprise or its content suppliers respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please write to us with your concerns.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">12) Disclaimer: </h3>
                <p class="py-4">
                  You acknowledge and undertake that you are accessing the services on the Website and application and transacting at your own risk and are using your best and prudent judgment before placing any order or availing the services or entering into any transactions with us through our Website and application. We shall not be held liable nor responsible for any representations or warranties/guarantees of the products save and except the limited manufacturer's warranty/guarantee on the respective Product is assigned unto us and we hereby expressly disclaim and any all responsibility and liability in that regard.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">13) Severability: </h3>
                <p class="py-4">
                  If any of these Terms is deemed invalid, void or unenforceable in whole or in part for any reasons, such invalidity or unenforceability condition or part thereof will be deemed severable and will not affect the validity and enforceability of any remaining condition or part of such condition / Terms
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">14) Amendments: </h3>
                <p class="py-4">
                  Amit Enterprise reserves its right to amend the Website and application, Policies, Conditions or Terms of use and at any time without prior notice. All such updates and amendments shall be posted on the Website and application and will be effective from the time of its posting on the Website and application. You are advised to regularly check for any amendments or updates to the terms and conditions contained in this User Agreement, Terms and Policies.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">15) Indemnification: </h3>
                <p class="py-4">
                  You agree to indemnify, defend and hold harmless us and affiliates including employees, directors, officers, agents and their successors and assigns from and against any and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us arising out of your actions or inactions or result from your breach of the Terms and Conditions herein or any document incorporated by reference/hyperlink or infringement of intellectual property rights or your violation of any law, rules or regulations or the rights of any third party.
                  <br>
                  You hereby expressly release Amit Enterprise and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions/inactions of the Manufacturers/Brand owners of the Products purchased through the website and application and specifically waiver any claims or demands that you may have in this behalf under any statute, contract or otherwise.
                  <br>
                  This clause shall survive the expiry or termination of this Agreement.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">16) Limitation on Liability: </h3>
                <p class="py-4">
                  In no event shall we or our affiliates be liable for any special, indirect, incidental, consequential or exemplary damages including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or in connection with the Website and application, its services or this User Agreement whatsoever, whether in an action of contract, negligence or other tortious action.
                  <br>
                  Without prejudice to the generality of this section the total liability of Amit Enterprise to you for all liabilities arising out of this User Agreement be it in tort or contract is limited to the value of the product ordered by you. However in case of missing a delivery in transit, Our liability is limited only to delivery at a later agreed time at no additional charge.
                  <br>
                </p>

                <h2 class="mt-4 text-base font-medium">PRICE & PAYMENT POLICY</h2>
                <h3 class="mt-4 text-base font-medium">1) Price Policy: </h3>
                <p class="py-4">
                  Prices and taxes thereon in respect of all products are subject to change from time to time, without prior notice by Amit Enterprise. We strive to provide you with the best prices possible on our Website and application. All the products listed on the Website and application will be sold at Indian Rupees either at Maximum Retail Price ( MRP) (inclusive of all taxes) or at an Offer/discounted price with taxes, unless otherwise specified. The prices mentioned at the time of ordering will be the prices charged to you on the date of delivery. Although prices of most of the products do not fluctuate on a daily basis but some of the commodities and fresh food products prices do change on a daily basis. In case of products such as Fresh Food, Vegetables and Fruits etc. the delivered weight may have variance and product will be billed accordingly on the actual weight.
                  <br>
                  There may be cases where M.R.P. of the product delivered is different from the M.R.P. quoted / reflected on the Website and application due to various reasons including time lag in updating prices post changes in M.R.P. or product belonging to a different batch or different M.R.P.s being used by manufacturers in different regions.
                  <br>
                  Despite our best efforts, there may be an instance of mispricing of items in our catalogue/offer on Website and application. However, we verify prices as part of our dispatch procedures. If a product's correct price is lower than our stated price, we charge the lower amount and send you the item. If a product's correct price is higher than our stated price, you may return at the time of delivery or we may not supply the product unless the problem get resolved and if you do not want to purchase the Product in such higher price we shall cancel your order and refund you the amount paid by you for such cancelled transaction as per existing refund policy.
                  <br>
                  Our online price may not match with offline price at store. You shall not raise any dispute on the same.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">2) Payment Policy: </h3>
                <p class="py-4">
                  Amit Enterprise offers you offline payment options  however Amit Enterprise reserves its right to add or delete any mode of payment without any prior notice.
                  <br>
                </p>
                <h4 class="text-base font-medium">A. Offline Payment Mode:</h4>
                <p class="py-4">
                  Cash on Delivery (CoD) - Buyer needs to make payment in cash to the delivery person at the time of receipt of delivery of the Products at its delivery location
                  <br>
                  Food Coupons on Delivery - Food Coupons shall be accepted at the sole discretion of Amit Enterprise against value of Ready to Eat / certain category of food items on the invoice at the time of Delivery of Products at the delivery location. Items permissible for purchase through food coupons shall change without prior notice and you shall have the option to cancel the item/order or shall pay in cash or card.
                  <br>
                  For CoD Payment option, maximum order value is Rs 20,000/- However Amit Enterprise can change this limit without further notice.
                  <br>
                  Card on Delivery: A mobile EDC machine may be made available at your door step to facilitate payment by Credit / Debit Card . However due to prevailing network condition in your area or any technical glitch in the equipment or due to any other reason if the EDC terminal is not able to collect the payment, you shall make payment by cash.
                  <br>
                  In cases where payment is attempted but remains unsuccessful and a successful charge slip does not get generated, you shall make the payment in cash for that particular order. In certain cases though you may receive a payment confirmation sms from your bank, the amount generally gets reversed in the same account. You may raise the incident with customer care number on the back of card to reverse the amount in case of unreasonable delay, Amit Enterprise will not be responsible for such failed attempts due to any network or technical reasons. We on our part, upon request of customer, after due verification with our back end team may give a written confirmation of the non-receipt of payment for you to appropriately take up with card issuing bank.
                  <br>
                </p>
                <h4 class="text-base font-medium">Order: </h4>
                <p class="py-4">
                  You can place your order with us either on our Website and application or over phone and choose any of our payment modes made available to you. In case of payment to be made offline, Buyer needs to pay the invoice amount in cash / coupon / card at the time of delivery of the Product at its doorstep/preferred location.
                  <br>
                  Orders will be processed for shipment/delivery upon selection of your preferred delivery location, date and time and confirmation by us. Amit Enterprise may change maximum order value of COD and items/locations available for COD without prior intimation.
                  <br>
                </p>
                <h4 class="text-base font-medium">Payment: </h4>
                <p class="py-4">
                  While availing any of the payment method/s available on the Website and application, we will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:
                  <br><br>
                  i. Lack of authorization for any transaction/s, or
                  <br><br>
                  ii. Exceeding the present limit mutually agreed by you and your issuing bank, or
                  <br><br>
                  iii. Any payment issues arising out of the transaction, or
                  <br><br>
                  iv. Decline of transaction for any other reason/s
                  <br><br>
                  Amit Enterprise reserves its right to change the price of any product in the Website and application any time without any notice. Prices stated at the time of an order is placed shall apply in respect to that order.
                  <br><br>
                  All payments made against the purchases of products on Website and application by you shall be in Indian Rupees only. Website and application will not facilitate transaction with respect to any other form of currency with respect to the purchases made on Website and application.
                  <br><br>
                  Amit Enterprise reserves its right to refuse to process transactions by buyers with a prior history of questionable charges including without limitation breach of any agreements by buyer with Amit Enterprise or breach/violation of any law or any charges imposed by bank or breach of any policy. Amit Enterprise may do such checks as it deems fit before approving the buyer's order ) for security or other reasons at the discretion of Amit Enterprise. As a result of such check if Amit Enterprise is not satisfied with the creditability of the Buyer or genuineness of the transaction, it will have the right to reject such order. Amit Enterprise may delay dispatch or cancel any transaction at its sole discretion, if Amit Enterprise is suspicious of any Buyer's authenticity or activity or if the Buyer is conducting high transaction volumes, to ensure safety of the transaction.
                  <br><br>
                  Buyer acknowledges that Amit Enterprise will not be liable for any damages, interests, claims etc. resulting from not processing a transaction or any delay in processing a transaction which is beyond control of Amit Enterprise.
                  <br>
                </p>
                <h2 class="mt-4 text-base font-medium">RETURN, EXCHANGE & REFUND </h2>
                <h3 class="mt-4 text-base font-medium">1) Return & Refund Policy: </h3>
                <p class="py-4">
                  We endeavour to provide the best quality products every single time you order with us.
                  <br>
                  We have a "no questions asked return and refund policy" which entitles all our customers to return the product at the time of delivery if they are not satisfied with the quality, freshness or physical condition of the product. At the time of delivery, we will take the returned product back with us and if already paid the corresponding value would be refunded to you through the same mode of payment used at the time of purchase. Alternatively, at your option, the said amount can be credited to your store credit account which can be used for your subsequent purchases.
                  <br>
                  After the delivery, the amount shall be refunded to you through the same mode of payment or via credit to your store credit account which can be used for subsequent purchases.
                  <br>
                  Products once sold and delivered to you, shall be eligible for return only if the product condition is found to be damaged, broken, faulty or different from the ordered one. You can return the product(s), purchased from us under your order as per below, provided the product(s) packs are sealed/unopened/unused and in original condition.
                  <br><br> - &nbsp;&nbsp; Product CategoryReason for Return &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp; Damaged / Faulty / Broken/ Leaking <br> - &nbsp;&nbsp; Bread / Fruits & Vegetable / Eggs &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;Within 1 day of delivery <br> - &nbsp;&nbsp; Dairy Products &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;Within 1 day of delivery <br> - &nbsp;&nbsp; Non Food &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;Within 1 days of delivery <br> - &nbsp;&nbsp; Packaged Food &nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;Within 1 days of delivery <br>
                  <br>
                  We do not make any cash refunds.
                  <br>
                  In case of prepaid orders, if any part of it remains short delivered due to some reason, corresponding value would be refunded to you through the same mode of payment used at the time of purchase.
                  <br>
                  The amount will be refunded to you between 3-15 working days depending upon the mode of payment chosen by you. Sometimes banks or financial intermediaries take a longer time to process the refund request. However, if the refund does not happen by the date advised, you may contact us and we will gladly help you. Alternatively at your option, the said amount can be credited to your store credit account which can be used for your subsequent purchases.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">2) Exchange Policy: </h3>
                <p class="py-4">
                  All products listed on our Website and application can be exchanged or returned subject to the following conditions:
                  <br>
                  Product Condition: A product cannot be exchanged or returned unless it is a defective, broken, faulty, leaking or not of satisfactory quality. In case of return/exchange you must return the product to us in the same original condition as is supplied by us. Products, under the following instances, cannot be accepted by us as/under Return:
                  <br><br>
                  i.&nbsp; damages due to mishandling of product;
                  <br><br>
                  ii.&nbsp; products with tampered or missing labels;
                  <br><br>
                  iii.&nbsp; Products without all original packaging and accessories, including the box, manufacturer's packaging if any, original invoice and all other items originally included with the product(s) delivered.
                  <br><br>
                  iv.&nbsp; Product Accessories / Freebies / Bundles & Combos: All the product accessories available with the product and / or all the freebies associated with the product and / or other products associated as "bundles" or "combos" with the product should also be returned so as to enable us to initiate the exchange or return process.
                  <br><br>
                  v.&nbsp; Validity Period for Return/Exchange: Subject to Return/exchange Policy, a purchased product can be exchanged or returned within 1 (One) to 3 (Three) days from the date of delivery/placing an order depending on the nature of the product as mentioned herein above.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">3) Cancellation Policy: </h3>
                <p class="py-4">
                  You can cancel your order fully or partially anytime up to the cut-off time of the slot for which you have placed an order by calling our customer service. In such a case we shall refund any payments already made by you for the order as per the prevailing Refund Policy.
                  <br>
                  Please contact us at +91 98793 34778 for cancellation or modification of your Order.
                  <br>
                  On receipt of the cancellation notice we shall cancel the order and refund the entire amount if the order has not been processed by us. Cancellation of Order can't be done/accepted once the products under your order is released for dispatch/ shipped out from our delivery location.
                  <br>
                  ALL RETURNS, EXCHANGE AND CANCELLATION OF PRODUCTS ARE SUBJECT TO MANUFACTURER'S WARRANTIES AND THE INOVICE TERMS AND CONDITIONS.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">4) Delivery/Shipping Policy: Delivery Timelines: We will make every effort to get your order delivered to you as per your convenient/ chosen available slot. </h3>
                <p class="py-4">
                  i.&nbsp; The products ordered by You shall be available for delivery as per allotted delivery slot during the delivery hours, namely, 07:30 hours to 21:30 hours on all days. If you fail to take delivery on the agreed slot as mentioned in (a) above, your order and delivery thereof shall be treated as cancelled.
                  <br><br>
                  ii.&nbsp; At the time of taking delivery of the products, You shall quote the PIN No. at the Delivery Point.
                  <br><br>
                  iii.&nbsp; The person at the Delivery Point authorized to deliver the product may decline to deliver the product if he finds any inconsistency as regards Order No. / PIN No or any other detail of the order
                  <br><br>
                  iv.&nbsp; Subject to availability of the ordered product at the Delivery Point on the delivery day, Amit Enterprise shall deliver the product to You as per schedule, failing which Amit Enterprise shall inform You with a revised schedule for delivery of the Product. You may ask for cancellation/modification of Order on account of this.
                  <br><br>
                  v.&nbsp; It is your responsibility to count and check all the products while accepting the Products as per your Order. No dispute will be entertained by Amit Enterprise once you accept the products at the Delivery Point.
                  <br><br>
                  vi.&nbsp; You shall accept the product without demur in the following circumstances:
                  <br><br>
                  a.&nbsp; If the products delivered to you are in accordance with your order, or
                  <br><br>
                  b.&nbsp; Loose Fruits and Vegetables with minor weight variation due to moisture loss
                  <br><br>
                  We request you to check all the Products at the time of delivery. Complaint pertaining to short weight will not be entertained, if the complaint is not lodged immediately on delivery.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">5) Shipping Charges: </h3>
                <p class="py-4">
                  Delivery charge of Rs. 2/- Pre KG/Litter will be leviable for each delivery. However Reliance may run certain promotions / offers or prescribe minimum order value for each transaction to waive the delivery charges.
                </p>
                <h3 class="mt-4 text-base font-medium">6) Order Tracking: </h3>
                <p class="py-4">
                  Once your order has been dispatched from our delivery point, an email or an SMS will be sent to you on the email address and mobile number provided by you under "Billing Address" and Order placed with Us. You can track your order online by the ID Tracking number sent to you by us on your Mobile/Email ID.
                  <br>
                  You may also log on to your account on the website and application or use the "Track my Order" link on the website and application to track your order.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">7) Order-Splitting: </h3>
                <p class="py-4">
                  Sometimes we may not be in a position to deliver an order to a particular location due to the nature of the product, its availability or due to the service limitations of our courier and logistic partners. In such cases, you may receive the items (of a single order) at different points of time or through different courier partners. The status of all items within an order can be tracked online.
                  <br>
                </p>
                <h3 class="mt-4 text-base font-medium">8) Delivery Locations: </h3>
                <p class="py-4">
                  We currently deliver orders to selected pin codes/areas across a city within Surat only. You may check the delivery serviceability of your area/locality with our customer care/support team at +91 98793 34778.
                  <br>
                </p>
                <h2 class="mt-4 text-base font-medium">SPECIAL PROMOTIONS</h2>
                <p class="py-4">
                  Amit Enterprise may at its sole discretion run certain Special Promotions form time to time under additional specific terms and conditions which shall be published on our website and application. The participation in such promotions is absolutely voluntary. Certain benefits of such promotions including cash back in customers store credit wallet shall have limited validity at the sole discretion of Amit Enterprise and maximum up to 6 months from the date of transaction. Any dispute regarding the non receipt of promotion benefit must be brought to notice of Amit Enterprise within 60 days from the date of end of offer / promotion.
                  <br>
                  Without prejudice to the generality of this section the total liability of Amit Enterprise to you for all liabilities arising out of such promotions be it in tort or contract is limited to the maximum value of the benefit / discount applicable to the specific promotion. However in case of missing a delivery in transit, Our liability is limited only to delivery at a later agreed time at no additional charge.
                  <br>
                </p>
                <h2 class="mt-4 text-base font-medium">TERMINATION POLICY</h2>
                <p class="py-4">
                  his User Agreement shall be effective form the date and time of your acceptance of the Terms of the Website and application and remain in force unless and until terminated by either of us. You may terminate this Agreement at any time, provided that you discontinue any further use of this Site. We may terminate this User Agreement at any time and may do so immediately without notice, and accordingly deny you access to the Site, Such termination will be without any liability to us. Upon any termination of the User Agreement by either of us, you must promptly destroy all materials downloaded or otherwise obtained from this Site, as well as all copies of such materials, whether made under the User Agreement or otherwise.. Any such termination of the User Agreement shall not cancel your obligation to pay for the product already ordered from the Site or affect any liability that may have arisen under the User Agreement.
                  <br>
                  Privacy policy: Use of this Site is subject to our Privacy Policy. We respect your privacy and shall not intentionally monitor or disclose any private messages or address information unless required by law, regulation or court order. You agree to respect the privacy and confidentiality of others.
                  <br>
                  We reserve the right to change or update these policies at any time without any prior notice effective immediately upon posting to this site.
                  <br>
                  Waiver: Our failure to enforce any provision of the Terms or respond to a breach by you shall not in any way waive our right to enforce subsequently any provision of the Terms or to act with respect to similar breaches.
                  <br>
                  Entire Agreement: This agreement constitutes the entire and final agreement regarding the Website and application and its contents, and supersedes any prior or contemporaneous communications between you and us regarding the Website and application and its contents.
                  <br>
                  Reserved Rights of Reliance: We may discontinue this Website and application at any time and for any reason, without notice. All rights not expressly granted herein are hereby reserved to us.
                  <br>
                  Applicable Law: Any dispute arising out of these terms and conditions, user agreement, privacy policy or the documents they incorporate by reference and/or your use of the Website and application shall be governed and construed in accordance with the laws of India, notwithstanding any conflicts of law.
                  <br>
                  Jurisdiction: You specifically consent to submit any dispute arising out of these terms and conditions/User agreement to the jurisdiction of courts of SURAT, India.
                  <br>
                </p>
                <h2 class="mt-4 text-base font-medium">DISCLAIMER:</h2>
                <p class="py-4">
                  This Website and application provides Content without any warranties or guarantees and in an "As Is" and "where is" basis. You must bear the risks associated with the use of the Site. This Website and application provides content from other Internet sites or resources and while Amit Enterprise and its software support service provider tries to ensure that material included on the Website and application is correct, reputable and of high quality, it cannot accept responsibility if this is not the case. Amit Enterprise shall not be held responsible for any errors or omissions or for the results obtained from the use of such information or for any technical problems you may experience with the Website and application. Amit Enterprise or its associates and technology partners make no representations or warranties about the accuracy, reliability, completeness, correctness and/or timeliness of any content, information, software, text, graphics, links or communications provided on or through the use of the Website and application or that the operation of the Website and application will be error free and/or uninterrupted. Consequently, Amit Enterprise And associates assumes no liability whatsoever for any monetary or other damage suffered by you on account of the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Website and application; and/or any interruption or errors in the operation of the Website and application.
                  <br>
                </p>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
      </div>
    </div>

  </div>
</template>

<script>

</script>
<style scoped>
.border {
  margin: 0;
  padding: 3px;
}
#tj-datetime-input input{
  border: 0;
}
.datetime-picker input:active{
  border: 0;
}
.datetime-picker input:focus{
  border: 0;
}
</style>
