<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Orders</h2>
        <!-- <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
              <router-link :to="{ name: 'user-create' }" tag="button" class="btn btn-primary shadow-md mr-2">
                  Add New User
              </router-link>

          </div> -->
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="grid grid-cols-12 gap-x-2">
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Field</label>
                            <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select">
                                <option value="first_name">Customer Name</option>
                                <option value="company_name">Company Name</option>
                                <option value="email">Customer Email</option>
                                <option value="mobile_no">Contact No</option>
                                <option value="city">City</option>
                                <option value="state">State</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-3 xl:col-span-2 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Type</label>
                            <select id="tabulator-html-filter-type" v-model="filter.type" class="form-select w-fullo">
                                <option value="like" selected>like</option>
                                <option value="=">=</option>
                                <option value="<">&lt;</option>
                                <option value="<=">&lt;=</option>
                                <option value=">">></option>
                                <option value=">=">>=</option>
                                <option value="!=">!=</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-span-6 md:col-span-4 xl:col-span-3 my-1">
                        <div class="flex items-center sm:mr-2">

                            <label class="mr-2">Value</label>
                            <input id="tabulator-html-filter-value" v-model="filter.value" type="text" class="form-control sm:w-40" placeholder="Search..." />
                        </div>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full" @click="onFilter">
                            Go
                        </button>
                    </div>
                    <div class="col-span-3 md:col-span-2 xl:col-span-1 my-1">
                        <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full" @click="onResetFilter">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
            <div class="flex mt-5 sm:mt-0">

                <div class="dropdown w-1/2 sm:w-auto">
                    <button class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto" aria-expanded="false">
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export
                        <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                    </button>
                    <div class="dropdown-menu w-40">
                        <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                            <a id="tabulator-export-csv" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportCsv">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                            </a>
                            <a id="tabulator-export-json" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportJson">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export JSON
                            </a>
                            <a id="tabulator-export-xlsx" href="javascript:;" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md" @click="onExportXlsx">
                                <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Modal Content -->
    <div id="delivery-number-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- BEGIN: Modal Header -->
                <div class="modal-header">
                    <h2 class="font-medium text-base mr-auto">
                        Confirm Invoice Number
                    </h2>
                </div>
                <!-- END: Modal Header -->
                <!-- BEGIN: Modal Body -->
                <div class="modal-body grid grid-cols-12 gap-4 gap-y-3">
                    <div class="col-span-12">
                        <div class="text-3xl mt-5 text-center">Are you sure?</div>
                        <div class="text-gray-600 mt-2 text-center">
                            Please note that this will automatically <br>
                            update Invoice Number, <br>
                        </div>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                        <label for="modal-form-1" class="form-label">Invoice Number</label>
                        <input id="invoice_no" type="text" v-model="delivery_data.invoice_no" class="form-control" autocomplete="off" placeholder="Invoice NO" />
                    </div>
                    <div class="col-span-12">
                        <div class="text-black mt-2"><b>Please confirm the Invoice No !</b></div>
                    </div>
                </div>
                <!-- END: Modal Body -->
                <!-- BEGIN: Modal Footer -->
                <div class="modal-footer text-right">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-28 mr-1">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary w-28" :disabled="delivery_data.invoice_pending" @click="deliver_order(delivery_data.id)">
                        Add Invoice
                        <LoadingIcon v-if="delivery_data.invoice_pending" icon="oval" color="white" class="w-4 h-4 ml-2" />
                    </button>
                </div>
                <!-- END: Modal Footer -->
            </div>
        </div>
    </div>
    <!-- END: Modal Content -->
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    inject
} from 'vue'
import xlsx from 'xlsx'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    BASE_URL
} from '@/constants/config.js'
import {
    helper as $h
} from '@/utils/helper'
import cash from 'cash-dom'

export default defineComponent({
    setup() {
        const router = useRouter()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const filter = reactive({
            field: 'first_name',
            type: 'like',
            value: ''
        })
        const swal = inject('$swal')
        const delivery_data = reactive({
          id: "",
          invoice_no: "",
          invoice_pending: false
        })

        const initTabulator = () => {
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/get_order_details',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                ajaxFiltering: true,
                ajaxSorting: true,
                printAsHtml: true,
                printStyled: true,
                headerSort: false,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                placeholder: 'No matching records found',
                columns: [

                    // For HTML table
                    {
                        title: 'Order',
                        width: 80,
                        field: 'id',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function(e, cell) {
                            router.push({
                                path: "/master/order/" + window.btoa(cell.getData().id)
                            })
                        },
                    },
                    {
                        title: 'Company',
                        minWidth: 120,
                        responsive: 0,
                        field: 'company_name',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea",
                        cellClick: function(e, cell) {
                            router.push({
                                path: "/master/order/" + window.btoa(cell.getData().id)
                            })
                        },
                    },
                    {
                        title: 'Customer Name',
                        minWidth: 150,
                        responsive: 0,
                        field: 'first_name',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().last_name != null) {
                                return cell.getData().first_name + ' ' + cell.getData().last_name
                            } else {
                                return cell.getData().first_name
                            }

                        },
                        cellClick: function(e, cell) {
                            router.push({
                                path: "/master/order/" + window.btoa(cell.getData().id)
                            })
                        },
                    },
                    {
                        title: 'Mobile No',
                        minWidth: 120,
                        responsive: 0,
                        field: 'mobile_no',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea",
                    },
                    {
                        title: 'Email',
                        minWidth: 120,
                        responsive: 0,
                        field: 'email',
                        vertAlign: 'middle',
                        hozAlign: 'center',
                        print: false,
                        download: true,
                        formatter: "textarea",
                    },
                    {
                        title: 'Total Amount',
                        minWidth: 150,
                        responsive: 0,
                        field: 'total',
                        vertAlign: 'middle',
                        hozAlign: 'center',
                        print: false,
                        download: true,
                        formatter(cell) {
                            return $h.myFloat(cell.getData().total)

                        }
                    },
                    {
                        title: 'Order Date',
                        minWidth: 120,
                        responsive: 0,
                        field: 'created_time',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                        formatter: "textarea"
                    },
                    {
                        title: 'Status',
                        minWidth: 90,
                        field: 'active_status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            return `<div class="flex items-center lg:justify-center ` + cell.getData().statusColor + `">` + cell.getData().active_status + `</div>`

                        }
                    },
                    {
                        title: ' ',
                        width: 70,
                        field: '',
                        responsive: 1,
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        headerSort: false,
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().status == 1) {

                              const a = cash(`<div class="p-0 m-0">
                              <button class="btn bg-theme-1 py-1 px-2 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="check" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {

                                    accept_order(cell.getData().id)
                                })
                                return a[0]

                            } else if (cell.getData().status == 2) {

                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <button class="btn btn-primary px-2 py-1">
                    Deliver
                  </button>
                </div>`)
                                cash(a).on('click', function () {

                                  cash('#delivery-number-modal').modal('show');
                                  delivery_data.invoice_no = ""
                                  delivery_data.id= cell.getData().id
                                })
                                return a[0]

                            } else if (cell.getData().status == 3) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
                  <span class="flex items-center" >

                  </span>
                </div>`)
                                return a[0]
                            } else if (cell.getData().status == 4) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
      <span class="flex items-center" >

      </span>
    </div>`)
                                return a[0]

                            }
                        }
                    },
                    {
                        title: ' ',
                        field: 'actions',
                        responsive: 1,
                        width: 70,
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {

                            if ([1, 2].includes(cell.getData().status)) {

                                const a = cash(`<div class="p-0 m-0">
                              <button class="btn bg-theme-6 py-1 px-2 text-white" title="Merge To Final Stock" data-te-toggle="tooltip">
                  <i data-feather="trash-2" class="w-5 h-5 mr-1"></i>
                  </button>

                  </div>`)
                                cash(a).on('click', function () {
                                    rejectOrder(cell.getData().id)
                                })

                                return a[0]
                            } else {
                              return ""
                            }
                        }
                    }

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        const accept_order = async (id) => {

          swal({
                title: "Are you sure?",
                text: "You are about to Accept Order!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let promise = axios({
                        url: "api/accept_order",
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: window.btoa(id)
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                initTabulator()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });
        }
        const deliver_order = async (id) => {

          if(delivery_data.invoice_no == "" || delivery_data.invoice_no == null) {
            swal("Warning !", "Invoice no, can not be Empty!", "warning")
            return;
          }
          swal({
                title: "Are you sure?",
                text: "You are about to Deliver Order!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let promise = axios({
                        url: "api/deliver_order",
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: window.btoa(id),
                            invoice_no: delivery_data.invoice_no
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")
                                cash('#delivery-number-modal').modal('hide');
                                delivery_data.invoice_no = ""
                                initTabulator()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }
        const rejectOrder = async (id) => {

          swal({
                title: "Are you sure?",
                text: "You are about to Cancel Order!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let promise = axios({
                        url: "api/cancel_order",
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: window.btoa(id)
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                initTabulator()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // On reset filter
        const onResetFilter = () => {
            filter.field = 'first_name'
            filter.type = 'like'
            filter.value = ''
            onFilter()
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'vendor_list.xlsx', {
                sheetName: 'Vendors'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {
            initTabulator()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            editUser,
            delivery_data,
            deliver_order
        }

    }
})
</script>
