<template>
<div>
    <div class="intro-y flex items-center mt-8 mb-4 w-full">
        <h2 class="text-lg font-medium mr-auto whitespace-nowrap">{{ form_data.product_id == "" ? 'Create Product' : 'Update Product' }}</h2>
        <div class="w-auto sm:w-auto flex sm:mt-0">
            <button v-if="form_data.product_id != '' && form_data.active_status == 1" type="button" :class="{'btn shadow-md mr-2': true,
            'btn-danger ' : form_data.active_status == 1}" @click="changeStatus">
                <LockIcon class="w-4 h-4 mr-1" /> Deactivate
            </button>
            <button v-if="form_data.product_id != '' && form_data.active_status == 0" type="button" :class="{'btn shadow-md mr-2': true,
            'btn-success ' : form_data.active_status == 0}" @click="changeStatus">
                <UnlockIcon class="w-4 h-4 mr-1" /> Activate
            </button>
            <button v-if="form_data.product_id != ''" type="button" class="btn btn-danger shadow-md mr-2" @click="deleteProduct">
                <Trash2Icon class="w-4 h-4 mr-1" /> Delete Product
            </button>

        </div>
    </div>
    <div class="intro-y box lg:mt-3">
        <div class="grid grid-cols-12 gap-6">
            <!-- BEGIN: Profile Menu -->
            <div class="col-span-4">

                <div class="grid grid-cols-12 gap-x-2 m-auto">
                    <div class="col-span-12">
                        <div class="m-auto mt-10 mx-3">
                            <img alt="Product" class="text-center m-auto" :class="{'w-full': form_data.base_image,'w-1/2': !form_data.base_image}" :src="form_data.base_image ? form_data.base_image : require(`@/assets/images/spair.png`)" />
                            <input type="file" ref="upload_btn" class="hidden" @change="onFileChange">
                            <div class="flex items-center justify-end -mt-10">
                                <button class="btn btn-secondary mt-3 p-2 text-right rounded-t-2xl" @click="onFileUploadClick">
                                    <CameraIcon class="h-5 w-5 text-black" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END: Profile Menu -->
            <div class="col-span-12 md:col-span-7 2xl:col-span-8">
                <!-- BEGIN: Change Password -->
                <div class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto">Product Information</h2>
                </div>
                <div class="p-5">

                    <div class="grid grid-cols-12 gap-x-2">
                        <div class="col-span-12 xl:col-span-4">
                            <div class="mt-3">
                                <label for="product_name" class="form-label">Product Name <span class="text-theme-6">*</span></label>
                                <input id="product_name" type="text" autocomplete="off" v-model="form_data.product_name" class="form-control" placeholder="Product Name" />
                            </div>
                            <span class="text-primary-3" v-if="errors.product_name">{{ errors.product_name[0] }}</span>
                        </div>
                        <div class="col-span-12 xl:col-span-4 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">Select Brand <span class="text-theme-6">*</span></label>
                                <TomSelect v-model="form_data.brand_id" :options="{
                    placeholder: 'Select User Type',
                  }" class="w-full" @change="getCompanyId">
                                    <option value="0">Select Brand</option>
                                    <option v-for="item in page_data.brand_items" :key="item.id" :value="item.id">{{item.brand_name}}</option>
                                </TomSelect>
                            </div>
                            <span class="text-primary-3" v-if="errors.brand_id">{{ errors.brand_id[0] }}</span>
                        </div>
                        <div class="col-span-12 xl:col-span-4 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">Select Company <span class="text-theme-6">*</span></label>
                                <TomSelect v-model="form_data.company_id" :options="{
                    placeholder: 'Select User Type',
                  }" class="w-full" disabled>
                                    <option value="0">Select Company</option>
                                    <option v-for="item in page_data.company_items" :key="item.id" :value="item.id">{{item.company_name}}</option>
                                </TomSelect>
                            </div>
                            <span class="text-primary-3" v-if="errors.brand_id">{{ errors.brand_id[0] }}</span>
                        </div>
                        <div class="col-span-12">
                            <div class="mt-3">
                                <label for="product_name" class="form-label">Product Description <span class="text-theme-6">*</span></label>
                                <!-- <textarea>
                                        <ClassicEditor v-model="page_data.details" />
                                      </textarea> -->
                                    <textarea class="form-control block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" v-model="form_data.details" />

                            </div>
                            <span class="text-primary-3" v-if="errors.product_name">{{ errors.product_name[0] }}</span>
                        </div>
                        <div class="col-span-7 xl:col-span-8 sm:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">Select Category <span class="text-theme-6">*</span></label>
                                <TomSelect v-model="form_data.category_id" :options="{
                    placeholder: 'Select Category',
                  }" class="w-full" multiple>
                                    <option v-for="item in page_data.category_items" :key="item.id" :value="item.id">{{item.category_name}}</option>
                                </TomSelect>
                            </div>
                            <span class="text-primary-3" v-if="errors.category_id">{{ errors.category_id[0] }}</span>
                        </div>
                        <div class="col-span-5 xl:col-span-4 sm:col-span-6">
                            <div class="mt-3">
                                <label for="quantity" class="form-label">New PKG <span class="text-theme-6">*</span></label>
                                <div class="input-group">
                                    <input id="quantity" type="number" autocomplete="off" v-model="form_data.quantity" class="form-control w-1/2" placeholder="PKG" />
                                    <div class="input-group-text w-1/2"><select class="bg-transparent w-full outline-none" name="uqc" id="uqc" v-model="form_data.uqc_id">
                                            <option value="0">UQC</option>
                                            <option v-for="item in page_data.uqc_items" :key="item.id" :value="item.id">{{item.uqc_name}}</option>
                                        </select></div>
                                </div>
                            </div>
                            <span class="text-primary-3" v-if="errors.quantity">{{ errors.quantity[0] }}</span>
                            <span class="text-primary-3" v-if="errors.uqc_id">{{ errors.uqc_id[0] }}</span>
                        </div>
                        <div class="col-span-12 xl:col-span-4 sm:col-span-6">
                            <div class="mt-3">
                                <label for="rate" class="form-label">Rate <span class="text-theme-6">*</span></label>
                                <input id="rate" type="number" autocomplete="off" v-model="form_data.rate" class="form-control" placeholder="Product Rate" />
                            </div>
                            <span class="text-primary-3" v-if="errors.rate">{{ errors.rate[0] }}</span>
                        </div>
                        <div class="col-span-12 xl:col-span-4 sm:col-span-6">
                            <div class="mt-3">
                                <label for="mrp" class="form-label">MRP (INR) <span class="text-theme-6">*</span></label>
                                <input id="mrp" type="number" autocomplete="off" v-model="form_data.mrp" class="form-control" placeholder="MRP(INR)" />
                            </div>
                            <span class="text-primary-3" v-if="errors.mrp">{{ errors.mrp[0] }}</span>
                        </div>
                        <div class="col-span-12 xl:col-span-4 sm:col-span-6">
                            <div class="mt-3">
                                <label for="exp_delivery" class="form-label">Expected Delivery (Days)</label>
                                <input id="exp_delivery" type="number" autocomplete="off" v-model="form_data.exp_delivery" class="form-control" placeholder="Expected Delivery Days" />
                            </div>
                            <span class="text-primary-3" v-if="errors.exp_delivery">{{ errors.exp_delivery[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="mt-3">
                                <label for="max_order_qty" class="form-label">Max Order Quantity</label>
                                <input id="max_order_qty" type="text" autocomplete="off" v-model="form_data.max_order_qty" class="form-control" placeholder="Max Order Quantity" />
                            </div>
                            <span class="text-primary-3" v-if="errors.max_order_qty">{{ errors.max_order_qty[0] }}</span>
                        </div>
                        <div class="col-span-12 lg:col-span-6">
                            <div class="mt-3">
                                <label for="address" class="form-label">Track Inventory</label>
                                <TomSelect v-model="form_data.track_inventory" :options="{
                  placeholder: 'Select User Type',
                }" class="w-full">
                                    <option value="0">Don't Track Inventory</option>
                                    <option value="1">Track Inventory</option>
                                </TomSelect>
                            </div>
                            <span class="text-primary-3" v-if="errors.track_inventory">{{ errors.track_inventory[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6" v-if="form_data.track_inventory == 1">
                            <div class="mt-3 mx-3">
                                <label for="avail_stock_quantity" class="form-label">Stock Quantity</label>
                                <input id="avail_stock_quantity" type="text" autocomplete="off" v-model="form_data.avail_stock_quantity" class="form-control" placeholder="Stock Quantity" />
                            </div>
                            <span class="text-primary-3" v-if="errors.avail_stock_quantity">{{ errors.avail_stock_quantity[0] }}</span>
                        </div>
                        <div class="col-span-12 sm:col-span-6">
                            <div class="flex items-center justify-between">
                                <div class="">

                                    <p class=""><span>
                                            <input type="checkbox" v-model="form_data.in_stock" class="form-check-input mt-3" name="" id="">
                                        </span> <span class="text-lg">In Stock ✔</span></p>
                                    <!-- </span> <span class="text-lg">In Stock ✔ / Out of Stock ▢</span></p> -->
                                </div>

                            </div>
                        </div>
                        <div class="col-span-12">

                            <div class="flex items-center justify-end">

                                <button class="btn btn-secondary mt-3 p-2 text-right mr-3" @click="this.$router.go(-1)">Cancel</button>
                                <button class="btn btn-primary mt-3 p-2 text-right" :disabled="form_data.loading == true" @click="submitForm">{{ form_data.product_id == "" ? 'Create Product' : 'Update Product' }}
                                    <LoadingIcon v-if="form_data.loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                            </div>
                        </div>
                    </div>
                    <!-- <p class="text-theme-6 mt-5">In case information is incorrect kindly contact Admin for updating the information.</p> -->
                </div>
            </div>
            <!-- END: Change Password -->

            <div id="success-notification-content" class="toastify-content hidden flex">
                <CheckCircleIcon class="text-theme-9" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">Password Updated Successfully!</div>
                </div>
            </div>

            <div id="failed-notification-content" class="toastify-content hidden flex">
                <InfoIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">Validation Error!</div>
                    <div class="text-gray-600 mt-1">
                        Please Check Password & Confirm Password (min 6 char required) and try again
                    </div>
                </div>
            </div>

            <div id="failed-server-content" class="toastify-content hidden flex">
                <InfoIcon class="text-theme-6" />
                <div class="ml-4 mr-4">
                    <div class="font-medium">Server Error!</div>
                    <div class="text-gray-600 mt-1">
                        Something went wrong! Please Try again
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    mapGetters
} from 'vuex'
import {
    BASE_URL
} from '@/constants/config.js'
import axios from 'axios'
import Toastify from 'toastify-js'
import {
    inject,
    onMounted,
    reactive,
    ref
} from 'vue'
import router from '@/router'
import {
    useRoute
} from 'vue-router'

export default {
    setup() {
        const base_image = ref('')
        const sub_image = ref('')
        const upload_btn = ref('')
        const route = useRoute()
        const swal = inject('$swal')
        const errors = ref([])
        const page_data = reactive({
            uqc_items: [],
            category_items: [],
            brand_items: [],
            company_items: [],
        })
        const form_data = reactive({
            product_name: "",
            short_description: "",
            details: "",
            brand_id: "0",
            company_id: "0",
            category_id: [],
            sub_category: [],
            uqc_id: "0",
            quantity: "",
            rate: "",
            mrp: "",
            discount: "",
            base_image: "",
            sub_image: "",
            in_stock: true,
            exp_delivery: "",
            return_policy: "",
            payment_type: "",
            price_type: "",
            active_status: "",
            track_inventory: "0",
            avail_stock_quantity: "",
            max_order_qty: "",
            product_id: route.params.product_id ? route.params.product_id : "",
            loading: false
            // new_img: ""
        })
        const onFileUploadClick = () => {
            upload_btn.value.click()
        }

        const onFileChange = (event) => {
            base_image.value = event.target.files[0];

            form_data.base_image = URL.createObjectURL(base_image.value);
        }

        const submitForm = async () => {
            let formData = new FormData();
            formData.append("product_name", form_data.product_name);
            formData.append("short_description", form_data.short_description);
            formData.append("details", form_data.details);
            formData.append("brand_id", form_data.brand_id == "0" ? "" : form_data.brand_id);
            formData.append("company_id", form_data.company_id == "0" ? "" : form_data.company_id);
            formData.append("category_id", form_data.category_id);
            formData.append("sub_category", form_data.sub_category);
            formData.append("uqc_id", form_data.uqc_id == "0" ? "" : form_data.uqc_id);
            formData.append("quantity", form_data.quantity);
            formData.append("rate", form_data.rate);
            formData.append("mrp", form_data.mrp);
            formData.append("discount", form_data.discount);
            formData.append("base_image", base_image.value);
            formData.append("sub_image", sub_image.value);
            formData.append("in_stock", form_data.in_stock ? 1 : 0);
            formData.append("exp_delivery", form_data.exp_delivery);
            formData.append("return_policy", form_data.return_policy);
            formData.append("payment_type", form_data.payment_type);
            formData.append("price_type", form_data.price_type);
            formData.append("product_id", form_data.product_id);
            formData.append("track_inventory", (form_data.track_inventory != "0" ? form_data.track_inventory : ""));
            formData.append("avail_stock_quantity", form_data.avail_stock_quantity);
            formData.append("max_order_qty", form_data.max_order_qty);

            let config = {
                'content-type': 'multipart/form-data',
                'Accept': "application/json"
            }

            let uri = ""

            if (form_data.product_id != "") {
                uri = "api/update_product"
            } else {
                uri = "api/create_product"

            }

            form_data.loading = true

            let promise = axios({
                url: uri,
                method: "post",
                baseURL: BASE_URL,
                data: formData,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.error == "NO") {

                        swal("Success !", data.message, "success")

                        router.push({
                            path: "/master/product/list"
                        })
                        form_data.loading = false
                        return
                    }
                    form_data.loading = false

                    swal("Error !", data.message, "error")
                    return
                })
                .catch(_error => {
                    form_data.loading = false
                    errors.value = _error.response.data.errors
                    swal("Error !", "Something went wrong.", "error")
                    return
                });
        }

        const get_uqc_details = async () => {
            let uri = ""
            if (form_data.product_id != "") {
                uri = "api/list_uqc"
            } else {
                uri = "api/list_uqc"

            }
            let config = {
                'Accept': "application/json"
            }
            let promise = axios({
                url: uri,
                method: "post",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.uqc_items = data.items
                })
                .catch(_error => {
                    page_data.uqc_items = []
                });
        }
        const get_category_details = async () => {
            let uri = ""
            if (form_data.product_id != "") {
                uri = "api/list_all_main_category"
            } else {
                uri = "api/list_all_main_category"

            }
            let config = {
                'Accept': "application/json"
            }
            let promise = axios({
                url: uri,
                method: "post",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.category_items = data.items
                })
                .catch(_error => {
                    page_data.category_items = []
                });
        }
        const get_brand_details = async () => {
            let uri = ""
            if (form_data.product_id != "") {
                uri = "api/list_brand"
            } else {
                uri = "api/list_brand"

            }
            let config = {
                'Accept': "application/json"
            }
            let promise = axios({
                url: uri,
                method: "post",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.brand_items = data.items
                })
                .catch(_error => {
                    page_data.brand_items = []
                });
        }
        const get_company_details = async () => {
            let uri = ""
            if (form_data.product_id != "") {
                uri = "api/list_company"
            } else {
                uri = "api/list_company"

            }
            let config = {
                'Accept': "application/json"
            }
            let promise = axios({
                url: uri,
                method: "post",
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    page_data.company_items = data.items
                })
                .catch(_error => {
                    page_data.company_items = []
                });
        }
        const get_product_details = async () => {

            let params = {
                id: route.params.product_id,
            }

            let config = {
                'Accept': "application/json"
            }

            let promise = axios({
                url: "api/edit_product",
                method: "post",
                baseURL: BASE_URL,
                data: params,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                },
                config: config
            });
            return promise
                .then(result => result.data)
                .then(data => {
                    form_data.product_name = data.item.product_name
                    form_data.short_description = data.item.short_description
                    form_data.details = data.item.details
                    form_data.brand_id = String(data.item.brand_id)
                    form_data.company_id = String(data.item.company_id)
                    form_data.category_id = data.item.category_id
                    form_data.sub_category = data.item.sub_category ? data.item.sub_category : ""
                    form_data.uqc_id = data.item.uqc_id
                    form_data.quantity = data.item.quantity
                    form_data.rate = data.item.rate
                    form_data.mrp = data.item.mrp
                    form_data.discount = data.item.discount ? data.item.discount : ""
                    form_data.base_image = data.item.base_image ? data.item.base_image : ""
                    form_data.sub_image = data.item.sub_image ? data.item.sub_image : ""
                    form_data.in_stock = data.item.in_stock ? true : false
                    form_data.exp_delivery = data.item.exp_delivery ? data.item.exp_delivery : ""
                    form_data.return_policy = data.item.return_policy ? data.item.return_policy : ""
                    form_data.payment_type = data.item.payment_type ? data.item.payment_type : ""
                    form_data.price_type = data.item.price_type ? data.item.price_type : ""
                    form_data.active_status = data.item.active_status ? data.item.active_status : ""
                    form_data.track_inventory = data.item.track_inventory ? String(data.item.track_inventory) : "0"
                    form_data.avail_stock_quantity = data.item.avail_stock_quantity ? data.item.avail_stock_quantity : ""
                    form_data.max_order_qty = data.item.max_order_qty ? data.item.max_order_qty : ""

                })
                .catch(_error => {
                    form_data.product_name = ""
                    form_data.short_description = ""
                    form_data.details = ""
                    form_data.brand_id = "0"
                    form_data.company_id = "0"
                    form_data.category_id = []
                    form_data.sub_category = []
                    form_data.uqc_id = "0"
                    form_data.quantity = ""
                    form_data.rate = ""
                    form_data.mrp = ""
                    form_data.discount = ""
                    form_data.base_image = ""
                    form_data.sub_image = ""
                    form_data.in_stock = 1
                    form_data.exp_delivery = ""
                    form_data.return_policy = ""
                    form_data.payment_type = ""
                    form_data.price_type = ""
                    form_data.active_status = ""
                    form_data.track_inventory = "0"
                    form_data.avail_stock_quantity = ""
                    form_data.max_order_qty = ""
                });
        }

        const changeStatus = () => {

            swal({
                title: "Are you sure?",
                text: "You are about to Change Status Product!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (form_data.product_id != "") {
                        uri = "api/inactive_product"
                    } else {
                        uri = "api/inactive_product"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: form_data.product_id
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                get_product_details()
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }
        const deleteProduct = () => {

            swal({
                title: "Are you sure?",
                text: "You are about to Delete Product!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    let config = {
                        'Accept': "application/json"
                    }

                    let uri = ""

                    if (form_data.product_id != "") {
                        uri = "api/delete_product"
                    } else {
                        uri = "api/delete_product"

                    }

                    let promise = axios({
                        url: uri,
                        method: "post",
                        baseURL: BASE_URL,
                        data: {
                            id: form_data.product_id
                        },
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token")
                        },
                        config: config
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.error == "NO") {

                                swal("Success !", data.message, "success")

                                router.push({
                                    path: "/master/product/list"
                                })
                                return
                            }

                            swal("Error !", data.message, "error")
                            return
                        })
                        .catch(_error => {
                            errors.value = _error.response.data.errors
                            swal("Error !", "Something went wrong.", "error")
                            return
                        });

                }
            });

        }
        const getCompanyId = () => {
            let match = false
            page_data.brand_items.forEach(element => {
                if (form_data.brand_id == element.id) {
                    form_data.company_id = String(element.company_id)
                    match = true;
                }
            });

            if (match == false) {
                form_data.company_id = String(0)

            }
        }

        onMounted(() => {
            get_brand_details()
            get_category_details()
            get_company_details()
            get_uqc_details()
            if (route.params.product_id) {
                get_product_details()
            }
        })

        return {
            page_data,
            form_data,
            upload_btn,
            base_image,
            sub_image,
            onFileChange,
            submitForm,
            onFileUploadClick,
            errors,
            changeStatus,
            deleteProduct,
            getCompanyId
        }
    },
}
</script>


<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input-group .input-group-text-custom {
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(226, 232, 240, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(247, 250, 252, var(--tw-bg-opacity));
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity));
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
</style>
